import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"

import Layout from "../components/layout"

class ProductPageTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.addtoCart = this.addtoCart.bind(this)
  }
  addtoCart() {
    const product = this.props.data.allItemsJson.edges[0].node;
    localStorage.setItem('selectedItem', product.id);
  }

  render() {
    const productInfo = get(this, 'props.data.allItemsJson')
    const data = productInfo.edges[0].node
    return (
      <Layout >
        <SEO title={data.name} />
        <div className="columns product-page-wrapper">
          <div style={{ backgroundImage: `url(${data.productImg.publicURL})` }} className="column is-half product-img">
          </div>
          <div className="column">
            <div className="columns" style={{ height: '100%', padding: '1.5rem 1.5rem 0 1.5rem' }}>
              <div className="column item-summary">
                <h1>{data.type}</h1>
                {/* <h1>{data.name}</h1> */}
                <p>{data.description}</p>
                <div className="columns" style={{marginTop: 'auto'}}>
                  <div className="column ">
                    <Link to="/" className="back-btn"><span className="icon"><i className="fas fa-arrow-left" /></span> <span className="text-btn">Back</span></Link>
                  </div>
                  <div className="column">
                    <Link to="/cart" onClick={this.addtoCart} className="button is-medium is-fullwidth"><span> Add to cart</span> <i style={{ marginLeft: 20 }} className="fas fa-shopping-cart"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProductPageTemplate

export const pageQuery = graphql`
  query ItemsQuery($id: String!) {
          allItemsJson(filter: {id: {eq: $id}}) {
          edges {
          node {
          name
          id
        price
        type
        description
        productImg{
          publicURL
        }
        }
      }
    }
  }

`